<template>
  <NuxtLink to="/cgbank">
    <svg
      width="170"
      height="24"
      viewBox="0 0 287 41"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1515_11144)">
        <path
          d="M47.1168 33.9036C46.3168 37.5684 41.6501 40.5703 36.6482 40.5703H7.78343C2.78153 40.5703 -0.685142 37.5684 0.114858 33.9036L5.78343 7.23698C6.51486 3.57222 11.2501 0.570312 16.2482 0.570312H45.1168C50.1187 0.570312 53.5168 3.57222 52.7853 7.23698L50.9834 15.5722C50.7168 16.9703 49.3149 18.0408 47.9815 18.0408H33.6463C32.2444 18.0408 31.3796 16.9741 31.6463 15.5722L32.5796 11.1036C33.1815 8.50555 31.7796 6.37222 29.513 6.37222C27.2463 6.37222 24.8463 8.50555 24.2444 11.1036L20.2444 30.037C19.7111 32.6389 21.1091 34.7036 23.4444 34.7036C25.7796 34.7036 28.0463 32.6389 28.5796 30.037L29.513 25.5684C29.8482 24.1703 31.1815 23.0351 32.5796 23.0351H46.9149C48.2482 23.0351 49.1815 24.1665 48.8501 25.5684L47.1168 33.9036Z"
          fill="currentColor"
        />
        <path
          d="M98.0528 38.037C97.7175 39.4389 96.3842 40.5703 95.0509 40.5703H59.5842C54.5823 40.5703 51.1156 37.5684 51.9156 33.9036L57.5842 7.23698C58.3156 3.57222 63.0509 0.570312 68.0528 0.570312H96.9214C101.923 0.570312 105.321 3.57222 104.59 7.23698L103.725 11.3703C103.39 12.7722 102.057 13.9036 100.723 13.9036H86.388C84.9899 13.9036 84.0566 12.7684 84.388 11.3703V11.1036C84.9899 8.50555 83.588 6.37222 81.3214 6.37222C79.0547 6.37222 76.6547 8.50555 76.0528 11.1036L72.0528 30.037C71.5194 32.6389 72.9175 34.7036 75.2528 34.7036C77.588 34.7036 79.8547 32.6389 80.388 30.037L81.8547 23.0351H77.7214L78.788 18.0332H99.7899C101.123 18.0332 102.057 19.1684 101.725 20.5665L98.0566 38.0332L98.0528 38.037Z"
          fill="currentColor"
        />
        <path
          d="M150.719 33.9035C149.85 37.5683 145.252 40.5702 140.25 40.5702H104.848C103.45 40.5702 102.517 39.4388 102.848 38.0369L110.25 3.03498C110.517 1.70164 111.919 0.566406 113.317 0.566406H148.719C153.717 0.566406 157.119 3.56831 156.384 7.23307L154.715 15.0997C154.048 18.1016 151.515 20.5664 148.982 20.5664C151.515 20.5664 152.982 23.035 152.384 26.0331L150.715 33.8997L150.719 33.9035ZM133.454 25.0388C133.656 23.9721 132.189 23.1035 130.254 23.1035H127.923L128.989 18.0369H131.321C133.256 18.0369 135.123 17.1683 135.389 16.1016L136.989 8.36831C137.256 7.23307 135.789 6.36831 133.854 6.36831H128.985L122.917 34.7035H127.785C129.721 34.7035 131.587 33.835 131.785 32.7683L133.454 25.035V25.0388Z"
          fill="currentColor"
        />
        <path
          d="M193.925 35.237C193.258 38.1703 189.525 40.5703 185.525 40.5703H161.392C157.392 40.5703 154.592 38.1703 155.258 35.237L156.527 29.237C157.258 25.5722 161.994 22.5703 166.996 22.5703H180.596L181.796 17.0389C182.196 14.9056 181.129 13.237 179.262 13.237C177.396 13.237 175.529 14.9056 175.129 17.0389L175.064 17.237C174.797 18.3722 173.796 19.237 172.664 19.237H161.529C160.394 19.237 159.662 18.3722 159.929 17.237L160.66 13.9036C161.262 10.9703 164.996 8.57031 168.996 8.57031H192.329C196.329 8.57031 199.06 10.9703 198.462 13.9036L193.929 35.237H193.925ZM178.725 31.237L179.723 26.5703H173.057L171.857 32.1056C171.457 34.2389 172.523 35.9075 174.39 35.9075C176.257 35.9075 178.123 34.2389 178.523 32.1056L178.721 31.2408L178.725 31.237Z"
          fill="currentColor"
        />
        <path
          d="M236.659 38.5704C236.392 39.7018 235.39 40.5704 234.259 40.5704H222.259C221.127 40.5704 220.392 39.7018 220.659 38.5704L225.192 17.2371L225.261 17.039C225.661 14.9056 224.594 13.2371 222.727 13.2371C220.861 13.2371 218.994 14.9056 218.594 17.039L213.992 38.5742C213.726 39.7056 212.724 40.5742 211.592 40.5742H199.592C198.461 40.5742 197.726 39.7056 197.992 38.5742L203.927 10.5742C204.194 9.43898 205.261 8.57422 206.392 8.57422H218.392L219.592 10.1056C221.127 9.17231 223.059 8.57422 225.059 8.57422H235.794C239.794 8.57422 242.526 10.9742 241.928 13.9076L236.659 38.5742V38.5704Z"
          fill="currentColor"
        />
        <path
          d="M280.128 38.5703C279.861 39.7017 278.859 40.5703 277.728 40.5703H265.728C264.596 40.5703 263.861 39.7017 264.128 38.5703L265.861 30.3684C266.326 28.2351 265.194 26.5665 263.328 26.5665C261.461 26.5665 259.663 28.2351 259.194 30.3684L257.461 38.5703C257.194 39.7017 256.192 40.5703 255.061 40.5703H243.061C241.93 40.5703 241.194 39.7017 241.461 38.5703L249.126 2.57031C249.324 1.43888 250.394 0.570312 251.526 0.570312H263.526C264.657 0.570312 265.324 1.43888 265.126 2.57031L260.992 21.9036H262.528L271.461 9.70174C271.926 9.03507 272.73 8.5665 273.526 8.5665H284.528C285.659 8.5665 286.326 9.49984 286.059 10.5665L285.392 13.7017C285.194 14.837 284.128 15.7017 282.992 15.7017H276.992C274.791 15.7017 272.924 16.7036 270.257 19.637L268.257 21.9036H276.391C280.391 21.9036 283.122 24.3036 282.524 27.237L280.124 38.5703H280.128Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1515_11144">
          <rect
            width="286.122"
            height="40"
            transform="translate(0 0.570312)"
          />
        </clipPath>
      </defs>
    </svg>
  </NuxtLink>
</template>
